<template>
  <div>

    <div class="desktop-page">
      <section class="top-banner">
        <img src="/images/RockitFuelTopBanner.jpg" alt="TopBanner">
        <div class="top-banner-text">
          <h1>POWER YOUR ONLINE PRESENCE WITH ROCKIT FUEL</h1>
          <p>Online sales, e-commerce advertising is an essential part of generating revenue. SearchRockit’s PA Program
            (Performance Authority) offers a win-win advertising program that shares in the success with merchants.
            <br><br>
            <b>Merchants only pay for ads when they make sales.</b> In simple terms, bids are made on key words and demographics
            by merchants and organizations as a percentage of the sale or number of desired actions. Merchants are billed
            when and only if there is a sale or a desired action achieved.</p>
          <router-link to="/rpm">
          <b-button
            href="/"
            class="rpm-button"
            size="is-medium"
            type="is-red-1"
            label="BRINGING PROVEN EFFICIENCY TO RPM"
          />
          </router-link>
        </div>
      </section>


      <section class="bitrockit-boosters">
        <img src="/images/Bitrockitbanner.jpg" alt="banner">
        <div class="bitrockit-text">
          <h1>BITROCKIT BOOSTERS</h1>
          <p>Our BitRockit Token allows e-commerce advertisers to purchase PA advertising at a discount. Every time you
            advertise on our PA program, you earn BitRockit Tokens. The more you advertise, the more tokens are earned
            that can be used to discount costs for advertisers. As a SearchRockit incentive, when you launch your campaign
            for the first time, you will receive a 500 Booster Pack of BitRockit tokens.</p>
        </div>

      </section>


      <section class="launch-date">
        <img src="/images/LaunchBanner.jpg" alt="launch">
        <div class="launch-text">
          <h2>LAUNCH DATE:</h2>
          <h1>October 2021</h1>
          <p>On October 2021, we will open the Performance Authority ad program. E-commerce merchants will be able to
            purchase ads at a discount on our Performance Authority program with BitRockit Tokens. The Performance
            Authority program allows merchants to purchase ads with fiat currency and buy a discount with BitRockit Tokens.
            <br><br>
            •  We will be providing a competitive rate program and options at the launch.
            <br><br>
            We do know the discounts will start out at 20% for all categories of products advertised on the platform.
            Over time, the algorithm will determine the discounts. BitRockit Tokens will always offer a discount and this
            is our guaranty to our supporters. We are estimating that discounts will ultimately range from 5-30% below ads
            purchased without the tokens.
          </p>
          <b-button
            class="questions-button"
            size="is-medium"
            type="is-blue-1"
            label="QUESTIONS? LET US KNOW"
          />
        </div>

      </section>
    </div>

    <div class="mobile-page">
      <section class="top-banner">
        <img src="/images/RockitFuelTopBanner.jpg" alt="TopBanner">
        <div class="top-banner-text column">
          <h1>POWER YOUR ONLINE PRESENCE WITH ROCKIT FUEL</h1>
          <p>Online sales, e-commerce advertising is an essential part of generating revenue. SearchRockit’s PA Program
            (Performance Authority) offers a win-win advertising program that shares in the success with merchants.
            <br><br>
            <b>Merchants only pay for ads when they make sales.</b> In simple terms, bids are made on key words and demographics
            by merchants and organizations as a percentage of the sale or number of desired actions. Merchants are billed
            when and only if there is a sale or a desired action achieved.</p>
          <router-link to="/rpm">
            <b-button
                href="/"
                class="rpm-button"
                size="is-small"
                type="is-red-1"
                label="BRINGING PROVEN EFFICIENCY TO RPM"
            />
          </router-link>
        </div>

        <img class="bitrockit-image" src="/images/BitrockitbannerMobile.png" alt="banner">
        <div class="bitrockit-text column has-background-light">
          <h1>BITROCKIT BOOSTERS</h1>
          <p>Our BitRockit Token allows e-commerce advertisers to purchase PA advertising at a discount. Every time you
            advertise on our PA program, you earn BitRockit Tokens. The more you advertise, the more tokens are earned
            that can be used to discount costs for advertisers. As a SearchRockit incentive, when you launch your campaign
            for the first time, you will receive a 500 Booster Pack of BitRockit tokens.</p>
        </div>

        <img class="launch-image" src="/images/LaunchBannerMobile.png" alt="launch">
        <div class="launch-text column has-background-blue-7">
          <h2>LAUNCH DATE:</h2>
          <h1>October 2021</h1>
          <p>On October 2021, we will open the Performance Authority ad program. E-commerce merchants will be able to
            purchase ads at a discount on our Performance Authority program with BitRockit Tokens. The Performance
            Authority program allows merchants to purchase ads with fiat currency and buy a discount with BitRockit Tokens.
            <br><br>
            •  We will be providing a competitive rate program and options at the launch.
            <br><br>
            We do know the discounts will start out at 20% for all categories of products advertised on the platform.
            Over time, the algorithm will determine the discounts. BitRockit Tokens will always offer a discount and this
            is our guaranty to our supporters. We are estimating that discounts will ultimately range from 5-30% below ads
            purchased without the tokens.
          </p>
          <b-button
              class="questions-button"
              size="is-small"
              type="is-blue-1"
              label="QUESTIONS? LET US KNOW"
          />
        </div>
      </section>
    </div>

  </div>
</template>

<script>
export default {
name: "RockitFuel"
}
</script>

<style lang="scss" scoped>

img {
  width: 100%;
  z-index: 1;
}

.mobile-page {
  display: none;
}
.desktop-page {

  .top-banner {
    position: relative;

    .top-banner-text {
      position: absolute;
      top: 20%;
      z-index: 3;
      color: black;
      width: 40%;
      margin-left: 5%;

      @media (max-width: 1280px) {
        top: 10%;
      }

      h1 {
        font-size: 3.7em;
        line-height: 1;
        font-weight: 600;

        @media (max-width: 1600px) {
          font-size: 3em;
        }

        @media (max-width: 1200px) {
          font-size: 2.5em;
        }


        @media (max-width: 1000px) {
          font-size: 2em;
        }
      }

      p {
        font-size: 1.2em;
        margin-top: 10%;
        margin-bottom: 10%;

        @media (max-width: 1600px) {
          font-size: 1em;
        }

        @media (max-width: 1200px) {
          font-size: 0.9em;
        }

        @media (max-width: 1200px) {
          font-size: 0.8em;
        }
      }
    }
  }


  .bitrockit-boosters {
    position: relative;

    .bitrockit-text {
      position: absolute;
      bottom: 20%;
      color: black;
      margin-left: 32%;

      @media (max-width: 1600px) {
        bottom: 10%;
      }

      h1 {
        font-size: 2.7em;
        font-weight: 600;
        margin-bottom: 10px;

        @media (max-width: 1600px) {
          font-size: 2em;
        }

        @media (max-width: 1200px) {
          font-size: 1.5em;
        }

        @media (max-width: 1200px) {
          font-size: 1.2em;
        }
      }

      p {
        font-size: 1.2em;
        margin-left: -20%;
        margin-right: 35%;

        @media (max-width: 1600px) {
          font-size: 1em;
        }

        @media (max-width: 1200px) {
          font-size: 0.9em;
        }

        @media (max-width: 1200px) {
          font-size: 0.8em;
        }
      }
    }
  }


  .launch-date {
    position: relative;

    .launch-text {
      position: absolute;
      top: 15%;
      margin-left: 5%;
      color: white;
      width: 40%;

      @media (max-width: 1600px) {
        top: 3%;
      }

      @media (max-width: 1200px) {
        width: 60%;
      }

      h2 {
        font-size: 3em;
        font-weight: 600;
        color: black;
        margin-bottom: -35px;

        @media (max-width: 1600px) {
          font-size: 2.2em;
          margin-bottom: -25px;
        }

        @media (max-width: 1200px) {
          font-size: 1.8em;
          margin-bottom: -15px;
        }

        @media (max-width: 1200px) {
          font-size: 1.2em;
        }
      }

      h1 {
        font-size: 5em;


        @media (max-width: 1600px) {
          font-size: 3.5em;
        }

        @media (max-width: 1200px) {
          font-size: 2.8em;
        }

        @media (max-width: 1200px) {
          font-size: 2.2em;
        }
      }

      p {
        font-size: 1.2em;
        margin-bottom: 50px;

        @media (max-width: 1600px) {
          font-size: 1em;
        }

        @media (max-width: 1300px) {
          font-size: 0.9em;
          margin-bottom: 20px;
        }

        @media (max-width: 1200px) {
          font-size: 0.8em;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .desktop-page {
    display: none;
  }

  .mobile-page {
    display: flex;
    color: black;

    h1 {
      font-size: 1.5em;
      font-weight: 600;
    }

    p {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .bitrockit-image {
      margin-bottom: -40%;
    }
    .bitrockit-text {
      position: relative;
    }

    .launch-image {
      //position: relative;
      margin-bottom: -10px;
    }
    .launch-text {
      h2 {
        font-size: 1.5em;
        font-weight: 600;
      }
      h1 {
        font-size: 2.5em;
        color: white;
      }
      p {
        color: white;
      }
    }
  }
}

</style>